<template>
  <div>
    <b-overlay
      :show="show"
      rounded="sm"
      no-fade
    >
      <b-container class="bv-example-row">
        <b-row>
          <b-col>
            <!-- bg-variant="light" -->
            <b-card
              no-body
              class="card-browser-states mt-1 text-center"
            >
              <b-card-header>
                <div>
                  <b-card-title> {{ name }} </b-card-title>
                </div>
                <button-download />

              </b-card-header>

              <!-- body -->
              <b-card-body>
                <div>
                  <pdf
                    v-for="i in numPages"
                    :key="i"
                    :src="src"
                    :page="i"
                    style="display: inline-block; width: 100%"
                  />
                </div>
              </b-card-body>
            <!--/ body -->
            </b-card>
          </b-col>
        </b-row>
      </b-container>
    </b-overlay>
  </div>
</template>

<script>
// https://www.npmjs.com/package/vue-pdf
import { mapActions, mapGetters } from 'vuex'
import pdf from 'vue-pdf'
import ButtonDownload from '../partials/viewer/ButtonDownload.vue'

export default {
  components: {
    pdf,
    ButtonDownload,
  },
  errorCaptured() {
    return false
  },
  data() {
    return {
      name: 'N/A',
      src: null,
      numPages: undefined,
      show: false,
    }
  },
  computed: {
    ...mapGetters({
      member: 'memberStore/member',
    }),
  },
  mounted() {
    if (this.$route.query.id == null) {
      this.$router.push({ name: 'member' })
    }
    this.fetchMember()
  },
  methods: {
    ...mapActions({
      fetchMemberById: 'memberStore/fetchMemberById',
      mediaFileShow: 'mediaFile/show',
    }),
    async fetchMember() {
      this.show = true
      await this.handleContract(this.$route.query.id).then(response => {
      }).catch(error => {
        this.responseCatch(error)
      })
    },
    async handleContract(hash) {
      this.show = true
      await this.mediaFileShow(hash).then(response => {
        this.name = this.$t('generic.contract')
        this.handleCretaeLoadinfTask(response.data, response.data.type)
      })
        .catch(error => {
          this.responseCatch(error)
        })
        .finally(
          this.show = false,
        )
    },
    async handleCretaeLoadinfTask(dataDocument, type) {
      this.show = true
      const file = new Blob([dataDocument], { type })

      await this.blobToBase64(file).then(response => {
        this.$nextTick(() => {
          this.src = pdf.createLoadingTask(response)
          // eslint-disable-next-line no-shadow
          this.src.promise.then(pdf => {
            this.numPages = pdf.numPages
            this.show = false
          }).catch(error => {
            this.responseCatch(error)
          })
        })
      }).catch(error => {
        this.responseCatch(error)
      })
    },
    blobToBase64(blob) {
      return new Promise((resolve, _) => {
        const reader = new FileReader()
        reader.onloadend = () => resolve(reader.result)
        reader.readAsDataURL(blob)
      })
    },
  },
}

</script>
